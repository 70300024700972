.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
iframe {
  pointer-events: none;
}

:root {
  --spinner-size: 20px;
  --spinner-border-width: 4px;
  --spinner-color: #fff;
  --spinner-empty-color: transparent;
  --spinner-speed: 1s;
}
.loading-spinner {
  margin: 2px;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border: var(--spinner-border-width) solid var(--spinner-color);
  border-top: var(--spinner-border-width) solid var(--spinner-empty-color);
  border-radius: 50%;
  animation: spin var(--spinner-speed) linear infinite;
}
.loading-spinner.blue-spinner {
  --spinner-color: #1e3a8a;
  --spinner-empty-color: transparent;
}
.loading-spinner.big-spinner {
  --spinner-size: 26px;
}
